<template>
    <nav id="navbar">
        <!-- START:: SEARCH -->
        <Search v-if="searchIsOpen" @closeSearch="toggleSearch" />
        <!-- END:: SEARCH -->

        <div class="container">
            <div class="navbar_wrapper">
                <div class="logo_and_links_wrapper">
                    <!-- START:: LOGO -->
                    <router-link class="logo_wrapper" to="/">
                        <img
                            class="logo_header"
                            src="../../assets/media/logo/logo.svg"
                            alt="Al-Moalem Al-Mosaed App Logo"
                            width="81"
                            height="67"
                        />
                    </router-link>
                    <!-- END:: LOGO -->

                    <!-- START:: NAVBAR LINKS -->
                    <!-- eslint-disable  -->
                    <ul class="navbar_links_wrapper">
                        <li
                            class="navbar_link"
                            v-for="link in navbarLinks"
                            :key="link.id"
                            v-if="
                                link.user_type == registeredUserType ||
                                link.user_type == 'all'
                            "
                        >
                            <router-link :to="link.url">
                                {{ link.text }}
                            </router-link>
                        </li>

                        <!-- <li class="navbar_link">
                            <router-link :to="{path: this.$route.path,  hash:'#contact'}" >
                                {{$t("NAVBAR.contact_us")}}
                            </router-link>
                        </li> -->
                    </ul>
                    <!-- eslint-enable -->
                    <!-- END:: NAVBAR LINKS -->
                </div>

                <!-- START:: NAVBAR BUTTONS -->
                <div class="navbar_btns_wrapper">
                    <!-- START:: LOGIN ROUTE -->
                    <router-link
                        to="/login"
                        class="login_route"
                        v-if="!getAuthenticatedUserData.token"
                    >
                        {{ $t("NAVBAR.login") }}
                    </router-link>
                    <!-- END:: LOGIN ROUTE -->

                    <!-- START:: REGISTER ROUTE -->
                    <router-link
                        to="/select-register-type"
                        class="register_route"
                        v-if="!getAuthenticatedUserData.token"
                    >
                        {{ $t("NAVBAR.register") }}
                    </router-link>
                    <!-- END:: REGISTER ROUTE -->

                    <!-- START:: ADD SUBJECT ROUTE -->
                    <router-link
                        to="/add-subject"
                        class="add_subject_route"
                        v-if="
                            getAuthenticatedUserData.token &&
                            registeredUserType == 'teacher'
                        "
                    >
                        <span> <i class="fal fa-plus"></i> </span>
                        {{ $t("NAVBAR.add_subject") }}
                    </router-link>
                    <!-- END:: ADD SUBJECT ROUTE -->

                    <!-- START:: NOTIFICATION BUTTON -->
                    <div class="user_notification_content_wrapper">
                        <button
                            class="notification_btn"
                            @click.stop="
                                toggleNotificationsMenu();
                                getNotifications();
                            "
                            v-if="getAuthenticatedUserData.token"
                        >
                            <i class="fal fa-bell"></i>
                            <span
                                class="badge"
                                v-show="
                                    notificationsData.unreadNotifications != 0
                                "
                            >
                                {{ notificationsData.unreadNotifications }}
                            </span>
                            <span class="toolTip">
                                {{ $t("TOOLTIPS.notifications") }}
                            </span>
                        </button>

                        <transition name="fadeInUp" mode="out-in">
                            <!-- START:: NOTIFICATIONS LIST -->
                            <ul
                                class="notifications_menu"
                                v-if="notificationsMenuIsOpen"
                            >
                                <!-- START:: EMPTY NOTIFICATIONS MESSAGE -->
                                <li
                                    class="empty_image"
                                    v-if="
                                        notificationsData.notificationsList
                                            .length == 0
                                    "
                                >
                                    <img
                                        src="../../assets/media/empty_messages/empty_notifications.png"
                                        alt="Empty Notifications"
                                        width="180"
                                        height="90"
                                    />
                                </li>
                                <!-- END:: EMPTY NOTIFICATIONS MESSAGE -->

                                <!-- START:: SINGLE NOTIFICATION -->
                                <li
                                    v-for="notification in notificationsData.notificationsList"
                                    :key="notification.id"
                                    class="notifications_menu_item"
                                >
                                    <!-- START:: NOTIFICATION ICON -->
                                    <div class="icon_wrapper">
                                        <span class="notification_icon_wrapper">
                                            <i class="fal fa-bell"></i>
                                        </span>
                                    </div>
                                    <!-- END:: NOTIFICATION ICON -->

                                    <!-- START:: NOTIFICATION BODY -->
                                    <div class="notification_body_wrapper">
                                        <p class="notification_body">
                                            {{ notification.body }}
                                        </p>
                                        <p class="notification_date">
                                            {{ notification.created_at }}
                                        </p>
                                    </div>
                                    <!-- END:: NOTIFICATION BODY -->

                                    <!-- START:: DELETE NOTIFICATION BUTTON -->
                                    <div class="delete_btn_wrapper">
                                        <button
                                            class="delete_notification_btn"
                                            @click.stop="
                                                deleteNotification(
                                                    notification.id
                                                )
                                            "
                                        >
                                            <i class="fal fa-trash-alt"></i>
                                        </button>
                                    </div>
                                    <!-- END:: DELETE NOTIFICATION BUTTON -->
                                </li>
                                <!-- END:: SINGLE NOTIFICATION -->
                            </ul>
                            <!-- END:: NOTIFICATIONS LIST -->
                        </transition>
                    </div>
                    <!-- END:: NOTIFICATION BUTTON -->

                    <!-- START:: CHATS ROUTE -->
                    <router-link
                        to="/chats"
                        class="chats_route"
                        v-if="getAuthenticatedUserData.token"
                    >
                        <i class="fal fa-comment-lines"></i>
                        <!-- <span class="badge"> 1 </span> -->
                        <span class="toolTip">
                            {{ $t("TOOLTIPS.chats") }}
                        </span>
                    </router-link>
                    <!-- END:: CHATS ROUTE -->

                    <!-- START:: STRENGTHENING REQUESTS ROUTE -->
                    <router-link
                        to="/strengthening-requests"
                        class="strengthening_requests"
                        v-if="
                            getAuthenticatedUserData.token &&
                            (registeredUserType == 'parent' ||
                                registeredUserType == 'teacher')
                        "
                    >
                        <i class="fal fa-book"></i>
                        <!-- <span class="badge bg-danger"> 1 </span> -->
                        <span class="toolTip">
                            {{ $t("TOOLTIPS.strengthening_requests") }}
                        </span>
                    </router-link>
                    <!-- END:: STRENGTHENING REQUESTS ROUTE -->

                    <!-- START:: MY COURSES ROUTE -->
                    <router-link
                        to="/my-courses"
                        class="my_courses_route"
                        v-if="
                            getAuthenticatedUserData.token &&
                            registeredUserType == 'student'
                        "
                    >
                        <i class="fal fa-book"></i>
                        <span class="toolTip">
                            {{ $t("TOOLTIPS.my_courses") }}
                        </span>
                    </router-link>
                    <!-- END:: MY COURSES ROUTE -->

                    <!-- START:: CART ROUTE -->
                    <router-link
                        to="/shopping-cart"
                        class="shopping_cart_btn"
                        v-if="
                            getAuthenticatedUserData.token &&
                            registeredUserType == 'student'
                        "
                    >
                        <i class="fal fa-shopping-cart"></i>
                        <span class="badge">
                            {{ getShoppingCartItems.data.length }}
                        </span>
                        <span class="toolTip">
                            {{ $t("TOOLTIPS.shopping_cart") }}
                        </span>
                    </router-link>
                    <!-- END:: CART ROUTE -->

                    <!-- START:: SEARCH BUTTON -->
                    <button
                        class="search_btn"
                        @click="toggleSearch"
                        v-if="
                            getAuthenticatedUserData.token &&
                            registeredUserType == 'student'
                        "
                    >
                        <i class="fal fa-search"></i>
                        <span class="toolTip">
                            {{ $t("TOOLTIPS.search") }}
                        </span>
                    </button>
                    <!-- END:: SEARCH BUTTON -->

                    <!-- START:: CHANGE THEME BUTTON -->
                    <!-- <button class="theme_changer" @click="changeAppTheme">
                        <i
                            class="fal fa-moon"
                            v-if="getAppTheme == 'light_theme'"
                        ></i>
                        <i class="fal fa-sun" v-else></i>
                        <span class="toolTip">
                            {{ $t("TOOLTIPS.change_theme") }}
                        </span>
                    </button> -->
                    <!-- END:: CHANGE THEME BUTTON -->

                    <!-- START:: USER PROFILE BUTTON -->
                    <div
                        class="user_profile_menu_wrapper"
                        v-if="getAuthenticatedUserData.token"
                    >
                        <button
                            class="user_profile_menu_btn"
                            @click.stop="toggleProfileMenu"
                        >
                            <div class="avatar_wrapper">
                                <img
                                    :src="getAuthenticatedUserData.avatar"
                                    width="45"
                                    height="45"
                                />
                            </div>
                        </button>

                        <transition name="fadeInUp" mode="out-in">
                            <ul
                                class="user_profile_menu"
                                v-if="profileMenuIsOpen"
                            >
                                <li
                                    class="user_profile_menu_item"
                                    @click.stop="toggleProfileMenu"
                                >
                                    <router-link to="/user-account">
                                        {{ $t("NAVBAR.my_account") }}
                                    </router-link>
                                </li>

                                <li
                                    class="user_profile_menu_item"
                                    @click.stop="toggleProfileMenu"
                                    v-if="registeredUserType == 'teacher'"
                                >
                                    <router-link to="/my-courses">
                                        {{ $t("NAVBAR.my_tutorials") }}
                                    </router-link>
                                </li>

                                <li
                                    class="user_profile_menu_item"
                                    @click.stop="toggleProfileMenu"
                                    v-if="registeredUserType == 'teacher'"
                                >
                                    <router-link to="/my-balance">
                                        {{ $t("NAVBAR.my_cash") }}
                                    </router-link>
                                </li>

                                <li
                                    class="user_profile_menu_item"
                                    @click.stop="toggleProfileMenu"
                                    v-if="
                                        registeredUserType == 'student' ||
                                        registeredUserType == 'teacher'
                                    "
                                >
                                    <router-link to="/favorites">
                                        {{ $t("NAVBAR.favorites") }}
                                    </router-link>
                                </li>

                                <li
                                    class="user_profile_menu_item"
                                    @click.stop="toggleProfileMenu"
                                    v-if="registeredUserType == 'student'"
                                >
                                    <router-link to="/my-orders">
                                        {{ $t("NAVBAR.my_orders") }}
                                    </router-link>
                                </li>

                                <li class="user_profile_menu_item">
                                    <button @click="logout">
                                        {{ $t("BUTTONS.logout") }}
                                    </button>
                                </li>
                            </ul>
                        </transition>
                    </div>
                    <!-- END:: USER PROFILE BUTTON -->

                    <!-- START:: SMALL SCREENS NAVBAR MENU BUTTON -->
                    <button
                        class="small_screens_navbar_toggeler"
                        @click="toggleSmallScreensNavBar"
                    >
                        <i class="fal fa-bars"></i>
                    </button>
                    <!-- END:: SMALL SCREENS NAVBAR MENU BUTTON -->
                </div>
                <!-- START:: NAVBAR BUTTONS -->
            </div>
        </div>

        <!-- START:: SMALL SCREENS NAVBAR -->
        <div
            class="small_screens_navbar_wrapper"
            :class="{ active: smallScreensNavbarIsOpen }"
        >
            <!-- START:: CLOSE BUTTON -->
            <button class="close_btn" @click="toggleSmallScreensNavBar">
                <i class="fal fa-times"></i>
            </button>
            <!-- END:: CLOSE BUTTON -->

            <!-- START:: LINKS -->
            <ul class="small_screens_links">
                <li @click="toggleSmallScreensNavBar">
                    <router-link
                        to="/login"
                        v-if="!getAuthenticatedUserData.token"
                    >
                        {{ $t("NAVBAR.login") }}
                    </router-link>
                </li>
                <li @click="toggleSmallScreensNavBar">
                    <router-link
                        to="/select-register-type"
                        v-if="!getAuthenticatedUserData.token"
                    >
                        {{ $t("NAVBAR.register") }}
                    </router-link>
                </li>
                <!-- eslint-disable  -->
                <li
                    v-for="link in navbarLinks"
                    :key="link.id"
                    v-if="
                        link.user_type == registeredUserType ||
                        link.user_type == 'all'
                    "
                    @click="toggleSmallScreensNavBar"
                >
                    <router-link :to="link.url"> {{ link.text }} </router-link>
                </li>
                <!-- eslint-enable -->
                <li
                    @click="toggleSmallScreensNavBar"
                    v-if="
                        getAuthenticatedUserData.token &&
                        registeredUserType == 'teacher'
                    "
                >
                    <router-link to="/add-subject">
                        {{ $t("NAVBAR.add_subject") }}
                    </router-link>
                </li>
                <li
                    @click="toggleSmallScreensNavBar"
                    v-if="getAuthenticatedUserData.token"
                >
                    <router-link to="/chats" class="chats_route">
                        {{ $t("NAVBAR.chats") }}
                    </router-link>
                </li>
                <li
                    @click="toggleSmallScreensNavBar"
                    v-if="
                        getAuthenticatedUserData.token &&
                        (registeredUserType == 'parent' ||
                            registeredUserType == 'teacher')
                    "
                >
                    <router-link
                        to="/strengthening-requests"
                        class="strengthening_requests"
                    >
                        {{ $t("NAVBAR.strengthening_requests") }}
                    </router-link>
                </li>
                <li
                    @click="toggleSmallScreensNavBar"
                    v-if="
                        getAuthenticatedUserData.token &&
                        registeredUserType == 'student'
                    "
                >
                    <router-link to="/my-courses" class="my_courses_route">
                        {{ $t("NAVBAR.my_courses") }}
                    </router-link>
                </li>
            </ul>
            <!-- END:: LINKS -->
        </div>
        <!-- END:: SMALL SCREENS NAVBAR -->
    </nav>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING SEARCH COMP
import Search from "../search/Search.vue";
// END:: IMPORTING SEARCH COMP

export default {
    name: "TheHeader",

    components: {
        Search,
    },

    computed: {
        // START:: VUEX GET APP THEME
        ...mapGetters("AppThemeModule", ["getAppTheme"]),
        // END:: VUEX GET APP THEME

        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA

        // START:: VUEX GET NOTIFICATIONS DATA
        ...mapGetters("NotificationsModule", ["notificationsData"]),
        // END:: VUEX GET NOTIFICATIONS DATA

        // START:: VUEX GET SHOPPING CART DATA
        ...mapGetters("StoreAndWishlistModule", ["getShoppingCartItems"]),
        // END:: VUEX GET SHOPPING CART DATA
    },

    data() {
        return {
            // START:: SMALL SCREENS NAVBAR APPERANCE DATA
            smallScreensNavbarIsOpen: false,
            // END:: SMALL SCREENS NAVBAR APPERANCE DATA

            // START:: SEARCH APPERANCE DATA
            searchIsOpen: false,
            // END:: SEARCH APPERANCE DATA

            // START:: USER PROFILE MENU APPERANCE DATA
            profileMenuIsOpen: false,
            // END:: USER PROFILE MENU APPERANCE DATA

            // START:: NOTIFICATIONS MENU APPERANCE DATA
            notificationsMenuIsOpen: false,
            // END:: NOTIFICATIONS MENU APPERANCE DATA

            registeredUserType: localStorage.getItem(
                "elmo3lm_elmosa3d_user_type"
            ),

            // START:: NAVBAR LINKS DATA
            navbarLinks: [
                {
                    id: "home_link",
                    url: "/",
                    text: this.$t("NAVBAR.home"),
                    user_type: "all",
                },
                {
                    id: "about_us_link",
                    url: "/about-us",
                    text: this.$t("NAVBAR.about_us"),
                    user_type: "all",
                },
                {
                    id: "sons_link",
                    url: "/my-sons",
                    text: this.$t("NAVBAR.my_sons"),
                    user_type: "parent",
                },
                {
                    id: "subjects_link",
                    url: "/course-section/distance-learning-lessons",
                    text: this.$t("NAVBAR.subjects"),
                    user_type: "student",
                },
                {
                    id: "teachers_link",
                    url: "/teachers",
                    text: this.$t("NAVBAR.teachers"),
                    user_type: "student",
                },
                {
                    id: "store_link",
                    url: "/store",
                    text: this.$t("NAVBAR.store"),
                    user_type: "student",
                },
                {
                    id: "added_subjects_link",
                    url: "/added-subjects",
                    text: this.$t("NAVBAR.added_subjects"),
                    user_type: "teacher",
                },
                // {
                //     id: "contact_us_link",
                //     url: "/contact-us",
                //     text: this.$t("NAVBAR.contact_us"),
                //     user_type: "all",
                // },
            ],
            // END:: NAVBAR LINKS DATA
        };
    },

    methods: {
        // START:: VUEX  APP THEME ACTIONS
        ...mapActions("AppThemeModule", ["changeAppTheme"]),
        // END:: VUEX APP THEME ACTIONS

        // START:: VUEX NOTIFICATIONS ACTIONS
        ...mapActions("NotificationsModule", [
            "getNotifications",
            "deleteNotification",
        ]),
        // END:: VUEX NOTIFICATIONS ACTIONS

        // START:: VUEX  AUTH ACTIONS
        ...mapActions("AuthenticationModule", ["logout"]),
        // END:: VUEX AUTH ACTIONS

        // START:: STICKY NAVBAR METHOD
        stickyNavbar() {
            setTimeout(() => {
                let theNavBar = document.querySelector("#navbar");
                let navBarHeight = theNavBar.clientHeight;
                window.addEventListener("scroll", () => {
                    if (window.scrollY > navBarHeight + 5) {
                        theNavBar.classList.add("sticky_navbar");
                        document.querySelector("body").style.paddingTop =
                            navBarHeight + "px";
                    } else {
                        theNavBar.classList.remove("sticky_navbar");
                        document.querySelector("body").style.paddingTop = 0;
                    }
                });
            }, 1000);
        },
        // END:: STICKY NAVBAR METHOD

        // START:: TOGGLE SMALL SCREENS NAVBAR
        toggleSmallScreensNavBar() {
            this.smallScreensNavbarIsOpen = !this.smallScreensNavbarIsOpen;
            this.searchIsOpen = false;
            this.profileMenuIsOpen = false;
            this.notificationsMenuIsOpen = false;
        },
        // END:: TOGGLE SMALL SCREENS NAVBAR

        // START:: TOGGLE SEARCH
        toggleSearch() {
            this.searchIsOpen = !this.searchIsOpen;
            this.smallScreensNavbarIsOpen = false;
            this.profileMenuIsOpen = false;
            this.notificationsMenuIsOpen = false;
        },
        // END:: TOGGLE SEARCH

        // START:: TOGGLE PROFILE MENU
        toggleProfileMenu() {
            this.profileMenuIsOpen = !this.profileMenuIsOpen;
            this.smallScreensNavbarIsOpen = false;
            this.searchIsOpen = false;
            this.notificationsMenuIsOpen = false;
        },
        // END:: TOGGLE PROFILE MENU

        // START:: TOGGLE NOTIFICATIONS MENU
        toggleNotificationsMenu() {
            this.notificationsMenuIsOpen = !this.notificationsMenuIsOpen;
            this.smallScreensNavbarIsOpen = false;
            this.searchIsOpen = false;
            this.profileMenuIsOpen = false;
        },
        // END:: TOGGLE NOTIFICATIONS MENU
    },

    created() {
        window.addEventListener("click", () => {
            this.profileMenuIsOpen = false;
            this.notificationsMenuIsOpen = false;
        });
    },

    mounted() {
        // START:: STICKY NAVBAR
        this.stickyNavbar();
        if (this.getAuthenticatedUserData.token) {
            this.getNotifications();
        }
        // END:: STICKY NAVBAR
    },
};
</script>

<style lang="scss">
.logo_header {
    width: 135px;
}
</style>
