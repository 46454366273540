var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{attrs:{"id":"navbar"}},[(_vm.searchIsOpen)?_c('Search',{on:{"closeSearch":_vm.toggleSearch}}):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"navbar_wrapper"},[_c('div',{staticClass:"logo_and_links_wrapper"},[_c('router-link',{staticClass:"logo_wrapper",attrs:{"to":"/"}},[_c('img',{staticClass:"logo_header",attrs:{"src":require("../../assets/media/logo/logo.svg"),"alt":"Al-Moalem Al-Mosaed App Logo","width":"81","height":"67"}})]),_c('ul',{staticClass:"navbar_links_wrapper"},_vm._l((_vm.navbarLinks),function(link){return (
                            link.user_type == _vm.registeredUserType ||
                            link.user_type == 'all'
                        )?_c('li',{key:link.id,staticClass:"navbar_link"},[_c('router-link',{attrs:{"to":link.url}},[_vm._v(" "+_vm._s(link.text)+" ")])],1):_vm._e()}),0)],1),_c('div',{staticClass:"navbar_btns_wrapper"},[(!_vm.getAuthenticatedUserData.token)?_c('router-link',{staticClass:"login_route",attrs:{"to":"/login"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.login"))+" ")]):_vm._e(),(!_vm.getAuthenticatedUserData.token)?_c('router-link',{staticClass:"register_route",attrs:{"to":"/select-register-type"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.register"))+" ")]):_vm._e(),(
                        _vm.getAuthenticatedUserData.token &&
                        _vm.registeredUserType == 'teacher'
                    )?_c('router-link',{staticClass:"add_subject_route",attrs:{"to":"/add-subject"}},[_c('span',[_c('i',{staticClass:"fal fa-plus"})]),_vm._v(" "+_vm._s(_vm.$t("NAVBAR.add_subject"))+" ")]):_vm._e(),_c('div',{staticClass:"user_notification_content_wrapper"},[(_vm.getAuthenticatedUserData.token)?_c('button',{staticClass:"notification_btn",on:{"click":function($event){$event.stopPropagation();_vm.toggleNotificationsMenu();
                            _vm.getNotifications();}}},[_c('i',{staticClass:"fal fa-bell"}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                                _vm.notificationsData.unreadNotifications != 0
                            ),expression:"\n                                notificationsData.unreadNotifications != 0\n                            "}],staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.notificationsData.unreadNotifications)+" ")]),_c('span',{staticClass:"toolTip"},[_vm._v(" "+_vm._s(_vm.$t("TOOLTIPS.notifications"))+" ")])]):_vm._e(),_c('transition',{attrs:{"name":"fadeInUp","mode":"out-in"}},[(_vm.notificationsMenuIsOpen)?_c('ul',{staticClass:"notifications_menu"},[(
                                    _vm.notificationsData.notificationsList
                                        .length == 0
                                )?_c('li',{staticClass:"empty_image"},[_c('img',{attrs:{"src":require("../../assets/media/empty_messages/empty_notifications.png"),"alt":"Empty Notifications","width":"180","height":"90"}})]):_vm._e(),_vm._l((_vm.notificationsData.notificationsList),function(notification){return _c('li',{key:notification.id,staticClass:"notifications_menu_item"},[_c('div',{staticClass:"icon_wrapper"},[_c('span',{staticClass:"notification_icon_wrapper"},[_c('i',{staticClass:"fal fa-bell"})])]),_c('div',{staticClass:"notification_body_wrapper"},[_c('p',{staticClass:"notification_body"},[_vm._v(" "+_vm._s(notification.body)+" ")]),_c('p',{staticClass:"notification_date"},[_vm._v(" "+_vm._s(notification.created_at)+" ")])]),_c('div',{staticClass:"delete_btn_wrapper"},[_c('button',{staticClass:"delete_notification_btn",on:{"click":function($event){$event.stopPropagation();return _vm.deleteNotification(
                                                notification.id
                                            )}}},[_c('i',{staticClass:"fal fa-trash-alt"})])])])})],2):_vm._e()])],1),(_vm.getAuthenticatedUserData.token)?_c('router-link',{staticClass:"chats_route",attrs:{"to":"/chats"}},[_c('i',{staticClass:"fal fa-comment-lines"}),_c('span',{staticClass:"toolTip"},[_vm._v(" "+_vm._s(_vm.$t("TOOLTIPS.chats"))+" ")])]):_vm._e(),(
                        _vm.getAuthenticatedUserData.token &&
                        (_vm.registeredUserType == 'parent' ||
                            _vm.registeredUserType == 'teacher')
                    )?_c('router-link',{staticClass:"strengthening_requests",attrs:{"to":"/strengthening-requests"}},[_c('i',{staticClass:"fal fa-book"}),_c('span',{staticClass:"toolTip"},[_vm._v(" "+_vm._s(_vm.$t("TOOLTIPS.strengthening_requests"))+" ")])]):_vm._e(),(
                        _vm.getAuthenticatedUserData.token &&
                        _vm.registeredUserType == 'student'
                    )?_c('router-link',{staticClass:"my_courses_route",attrs:{"to":"/my-courses"}},[_c('i',{staticClass:"fal fa-book"}),_c('span',{staticClass:"toolTip"},[_vm._v(" "+_vm._s(_vm.$t("TOOLTIPS.my_courses"))+" ")])]):_vm._e(),(
                        _vm.getAuthenticatedUserData.token &&
                        _vm.registeredUserType == 'student'
                    )?_c('router-link',{staticClass:"shopping_cart_btn",attrs:{"to":"/shopping-cart"}},[_c('i',{staticClass:"fal fa-shopping-cart"}),_c('span',{staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.getShoppingCartItems.data.length)+" ")]),_c('span',{staticClass:"toolTip"},[_vm._v(" "+_vm._s(_vm.$t("TOOLTIPS.shopping_cart"))+" ")])]):_vm._e(),(
                        _vm.getAuthenticatedUserData.token &&
                        _vm.registeredUserType == 'student'
                    )?_c('button',{staticClass:"search_btn",on:{"click":_vm.toggleSearch}},[_c('i',{staticClass:"fal fa-search"}),_c('span',{staticClass:"toolTip"},[_vm._v(" "+_vm._s(_vm.$t("TOOLTIPS.search"))+" ")])]):_vm._e(),(_vm.getAuthenticatedUserData.token)?_c('div',{staticClass:"user_profile_menu_wrapper"},[_c('button',{staticClass:"user_profile_menu_btn",on:{"click":function($event){$event.stopPropagation();return _vm.toggleProfileMenu.apply(null, arguments)}}},[_c('div',{staticClass:"avatar_wrapper"},[_c('img',{attrs:{"src":_vm.getAuthenticatedUserData.avatar,"width":"45","height":"45"}})])]),_c('transition',{attrs:{"name":"fadeInUp","mode":"out-in"}},[(_vm.profileMenuIsOpen)?_c('ul',{staticClass:"user_profile_menu"},[_c('li',{staticClass:"user_profile_menu_item",on:{"click":function($event){$event.stopPropagation();return _vm.toggleProfileMenu.apply(null, arguments)}}},[_c('router-link',{attrs:{"to":"/user-account"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.my_account"))+" ")])],1),(_vm.registeredUserType == 'teacher')?_c('li',{staticClass:"user_profile_menu_item",on:{"click":function($event){$event.stopPropagation();return _vm.toggleProfileMenu.apply(null, arguments)}}},[_c('router-link',{attrs:{"to":"/my-courses"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.my_tutorials"))+" ")])],1):_vm._e(),(_vm.registeredUserType == 'teacher')?_c('li',{staticClass:"user_profile_menu_item",on:{"click":function($event){$event.stopPropagation();return _vm.toggleProfileMenu.apply(null, arguments)}}},[_c('router-link',{attrs:{"to":"/my-balance"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.my_cash"))+" ")])],1):_vm._e(),(
                                    _vm.registeredUserType == 'student' ||
                                    _vm.registeredUserType == 'teacher'
                                )?_c('li',{staticClass:"user_profile_menu_item",on:{"click":function($event){$event.stopPropagation();return _vm.toggleProfileMenu.apply(null, arguments)}}},[_c('router-link',{attrs:{"to":"/favorites"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.favorites"))+" ")])],1):_vm._e(),(_vm.registeredUserType == 'student')?_c('li',{staticClass:"user_profile_menu_item",on:{"click":function($event){$event.stopPropagation();return _vm.toggleProfileMenu.apply(null, arguments)}}},[_c('router-link',{attrs:{"to":"/my-orders"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.my_orders"))+" ")])],1):_vm._e(),_c('li',{staticClass:"user_profile_menu_item"},[_c('button',{on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t("BUTTONS.logout"))+" ")])])]):_vm._e()])],1):_vm._e(),_c('button',{staticClass:"small_screens_navbar_toggeler",on:{"click":_vm.toggleSmallScreensNavBar}},[_c('i',{staticClass:"fal fa-bars"})])],1)])]),_c('div',{staticClass:"small_screens_navbar_wrapper",class:{ active: _vm.smallScreensNavbarIsOpen }},[_c('button',{staticClass:"close_btn",on:{"click":_vm.toggleSmallScreensNavBar}},[_c('i',{staticClass:"fal fa-times"})]),_c('ul',{staticClass:"small_screens_links"},[_c('li',{on:{"click":_vm.toggleSmallScreensNavBar}},[(!_vm.getAuthenticatedUserData.token)?_c('router-link',{attrs:{"to":"/login"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.login"))+" ")]):_vm._e()],1),_c('li',{on:{"click":_vm.toggleSmallScreensNavBar}},[(!_vm.getAuthenticatedUserData.token)?_c('router-link',{attrs:{"to":"/select-register-type"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.register"))+" ")]):_vm._e()],1),_vm._l((_vm.navbarLinks),function(link){return (
                    link.user_type == _vm.registeredUserType ||
                    link.user_type == 'all'
                )?_c('li',{key:link.id,on:{"click":_vm.toggleSmallScreensNavBar}},[_c('router-link',{attrs:{"to":link.url}},[_vm._v(" "+_vm._s(link.text)+" ")])],1):_vm._e()}),(
                    _vm.getAuthenticatedUserData.token &&
                    _vm.registeredUserType == 'teacher'
                )?_c('li',{on:{"click":_vm.toggleSmallScreensNavBar}},[_c('router-link',{attrs:{"to":"/add-subject"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.add_subject"))+" ")])],1):_vm._e(),(_vm.getAuthenticatedUserData.token)?_c('li',{on:{"click":_vm.toggleSmallScreensNavBar}},[_c('router-link',{staticClass:"chats_route",attrs:{"to":"/chats"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.chats"))+" ")])],1):_vm._e(),(
                    _vm.getAuthenticatedUserData.token &&
                    (_vm.registeredUserType == 'parent' ||
                        _vm.registeredUserType == 'teacher')
                )?_c('li',{on:{"click":_vm.toggleSmallScreensNavBar}},[_c('router-link',{staticClass:"strengthening_requests",attrs:{"to":"/strengthening-requests"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.strengthening_requests"))+" ")])],1):_vm._e(),(
                    _vm.getAuthenticatedUserData.token &&
                    _vm.registeredUserType == 'student'
                )?_c('li',{on:{"click":_vm.toggleSmallScreensNavBar}},[_c('router-link',{staticClass:"my_courses_route",attrs:{"to":"/my-courses"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.my_courses"))+" ")])],1):_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }